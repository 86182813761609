:root {
  --primary-color: #4263e2;
  --secondary-color: #f5f6f8;
  --blue-tint: #f3f2ff;
  --light-blue: #6984ec;
  --dark-blue-tint: #e3e4ef;
  --dim-text: #6a6a6a;
  --light-gray: #dddddd;
  --dark-gray: #444444;
  --light-black: #222222;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
    url(../assets/fonts/Outfit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 500;
  src: local("Outfit"),
    url(../assets/fonts/Outfit-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 600;
  src: local("Outfit"),
    url(../assets/fonts/Outfit-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 700;
  src: local("Outfit"), url(../assets/fonts/Outfit-Bold.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: "Manrope", sans-serif !important;
  margin: 0px;
  overflow: auto;
  height: 100% !important;
  width: 100%;
}

.fullscreen {
  min-height: calc(100vh - 90px);
  padding-bottom: 1rem;
}

.dim-text {
  color: var(--dim-text);
}

.link {
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-color);
  border-bottom: 1px solid transparent;
  position: relative;
}
.link:hover {
  /* border-bottom: 1px solid var(--primary-color); */
}
.link:after {
  content: "»";
  opacity: 0;
  position: absolute;
  right: 0px;
  transition: 0.5s;
}
.link:hover:after {
  opacity: 1;
  right: -14px;
}

.animate-hue {
  animation: hueAnimation infinite alternate-reverse 2.5s ease-in-out;
}

@keyframes hueAnimation {
  from {
    opacity: 1;
    transform: scale(1.25);
  }
  to {
    opacity: 0.75;
    transform: scale(1);
  }
}

.error-input-animation {
  transition: box-shadow 0.5s;
  animation: shake 0.2s ease-in-out 0s 1;
  box-shadow: 0 0 0.3em var(--dim-text);
}

@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 5px;
  }
  75% {
    margin-left: -5px;
  }
  100% {
    margin-left: 0;
  }
}

.custom-scroll {
  scrollbar-color: var(--primary-color) var(--secondary-color);
  scrollbar-width: thin;
  padding-right: 0.5rem;
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  margin: 2px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  height: 2px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.1rem;
  height: 2px;
  background-color: #dddddd;
}

.table-faded-effect {
  position: absolute;
  right: -5px;
  top: 0;
  height: 100%;
  width: 50px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 95%
  );
  filter: blur(2px);
  z-index: 1;
}
